/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = ({ location, prevLocation }) => {

  let currentPathName = location.pathname;
  let oldPathname = prevLocation ? prevLocation.pathname : null;
  if (currentPathName === "/cost-saving-calculator/" || currentPathName === "/cost-saving-calculator") {
    if (oldPathname !== "/cost-saving-calculator/" && oldPathname !== "/cost-saving-calculator" && oldPathname !== null) {
      window.location.href = location.pathname;
    } 
  }
}