// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cost-saving-calculator-jsx": () => import("./../../../src/pages/cost-saving-calculator.jsx" /* webpackChunkName: "component---src-pages-cost-saving-calculator-jsx" */),
  "component---src-pages-country-reports-jsx": () => import("./../../../src/pages/country-reports.jsx" /* webpackChunkName: "component---src-pages-country-reports-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-story-jsx": () => import("./../../../src/pages/our-story.jsx" /* webpackChunkName: "component---src-pages-our-story-jsx" */),
  "component---src-pages-partnerships-jsx": () => import("./../../../src/pages/partnerships.jsx" /* webpackChunkName: "component---src-pages-partnerships-jsx" */),
  "component---src-pages-press-and-media-jsx": () => import("./../../../src/pages/press-and-media.jsx" /* webpackChunkName: "component---src-pages-press-and-media-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-products-and-services-jsx": () => import("./../../../src/pages/products-and-services.jsx" /* webpackChunkName: "component---src-pages-products-and-services-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-what-is-payment-orchestration-jsx": () => import("./../../../src/pages/what-is-payment-orchestration.jsx" /* webpackChunkName: "component---src-pages-what-is-payment-orchestration-jsx" */),
  "component---src-templates-blog-article-jsx": () => import("./../../../src/templates/blog-article.jsx" /* webpackChunkName: "component---src-templates-blog-article-jsx" */),
  "component---src-templates-blog-listing-jsx": () => import("./../../../src/templates/blog-listing.jsx" /* webpackChunkName: "component---src-templates-blog-listing-jsx" */),
  "component---src-templates-country-report-focus-jsx": () => import("./../../../src/templates/country-report-focus.jsx" /* webpackChunkName: "component---src-templates-country-report-focus-jsx" */),
  "component---src-templates-flexipage-jsx": () => import("./../../../src/templates/flexipage.jsx" /* webpackChunkName: "component---src-templates-flexipage-jsx" */),
  "component---src-templates-landing-page-jsx": () => import("./../../../src/templates/landing-page.jsx" /* webpackChunkName: "component---src-templates-landing-page-jsx" */)
}

